/* unplugin-vue-components disabled */import { _FabComponent as __unplugin_components_7 } from '@varlet/ui';import '@varlet/ui/es/fab/style/index.mjs';
import { _CollapseComponent as __unplugin_components_6 } from '@varlet/ui';import '@varlet/ui/es/collapse/style/index.mjs';
import { _CollapseItemComponent as __unplugin_components_5 } from '@varlet/ui';import '@varlet/ui/es/collapse-item/style/index.mjs';
import { _InputComponent as __unplugin_components_4 } from '@varlet/ui';import '@varlet/ui/es/input/style/index.mjs';
import { _ButtonComponent as __unplugin_components_3 } from '@varlet/ui';import '@varlet/ui/es/button/style/index.mjs';
import { _IconComponent as __unplugin_components_2 } from '@varlet/ui';import '@varlet/ui/es/icon/style/index.mjs';
import { _RowComponent as __unplugin_components_1 } from '@varlet/ui';import '@varlet/ui/es/row/style/index.mjs';
import { _ColComponent as __unplugin_components_0 } from '@varlet/ui';import '@varlet/ui/es/col/style/index.mjs';
import "core-js/modules/es.array.push.js";
import { createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-23490e5e"), n = n(), _popScopeId(), n);
const _hoisted_1 = ["id"];
const _hoisted_2 = {
  class: "itemhead"
};
const _hoisted_3 = {
  key: 0,
  class: "danguan"
};
const _hoisted_4 = {
  class: "itemleft"
};
const _hoisted_5 = {
  class: "text"
};
const _hoisted_6 = {
  class: "text",
  style: {
    "height": "15px"
  }
};
const _hoisted_7 = {
  class: "itemmacth"
};
const _hoisted_8 = {
  class: "sup-text"
};
const _hoisted_9 = {
  key: 0,
  class: "corner-text"
};
const _hoisted_10 = {
  class: "itemgoal"
};
const _hoisted_11 = {
  key: 0,
  class: "all"
};
const _hoisted_12 = {
  key: 0,
  class: "all"
};
const _hoisted_13 = {
  key: 0,
  class: "ban"
};
const _hoisted_14 = {
  class: "itemmacth"
};
const _hoisted_15 = {
  class: "sup-text"
};
const _hoisted_16 = {
  key: 0,
  class: "corner-text"
};
const _hoisted_17 = {
  class: "textIcon"
};
const _hoisted_18 = {
  key: 0,
  class: "itemIcon"
};
const _hoisted_19 = {
  key: 1,
  class: "itemIcon"
};
const _hoisted_20 = {
  class: "itemcontent"
};
const _hoisted_21 = {
  class: "noteDiv"
};
const _hoisted_22 = {
  style: {
    "width": "95%"
  }
};
const _hoisted_23 = {
  class: "item"
};
const _hoisted_24 = {
  class: "item"
};
const _hoisted_25 = {
  class: "item"
};
const _hoisted_26 = {
  class: "item"
};
const _hoisted_27 = {
  class: "item"
};
const _hoisted_28 = {
  class: "item"
};
import { onMounted, ref } from 'vue';
import AnalyzeView from './AnalyzeView.vue';
import AnalyzeList from './AnalyzeList.vue';
import AnalyzeTtgView from './AnalyzeTtgView.vue';
import AnalyzeBSView from './AnalyzeBSView.vue';
import AnalyzeHeatView from './AnalyzeHeatView.vue';
import AnalyzeMoney from './AnalyzeMoney.vue';
import request from '@/utils/request';
import { useStore } from 'vuex';
import { showNotify } from '@nutui/nutui';
import service from '@/utils/request';
export default {
  __name: 'CollectList',
  setup(__props) {
    const timeNotify = msg => {
      showNotify.warn(msg, {
        duration: 2000
      });
    };
    const successNotify = msg => {
      showNotify.success(msg);
    };
    const store = useStore();
    const fullUser = ref(store.state.fullUser);
    const value = ref([]);
    const val = ref(0);
    const isShow = ref(false);
    const options = ref([{
      text: '默认收藏',
      value: 0
    }]);
    const updateData = ref({});
    const JcCollectRef = ref(null);
    const JcCollectData = ref({
      collectName: "",
      memo: "",
      collectId: ""
    });
    const submitCollect = () => {
      if (JcCollectData.value.collectId == '') {
        request.post('/system/collect', JcCollectData.value).then(res => {
          collectList();
          isShow.value = false;
        });
      } else {
        request.put('/system/collect', JcCollectData.value).then(res => {
          collectList();
          isShow.value = false;
        });
      }
    };
    const insertCollect = () => {
      JcCollectData.value = {
        collectName: "",
        memo: "",
        collectId: ""
      };
      isShow.value = true;
    };
    const updateCollect = () => {
      JcCollectData.value = updateData.value;
      isShow.value = true;
    };
    const menuChange = value => {
      let list = options.value;
      for (let i = 0; i < list.length; i++) {
        if (list[i].value == value) {
          updateData.value = {
            collectName: list[i].text,
            memo: list[i].memo,
            collectId: list[i].value
          };
        }
      }
      matchList(value);
    };
    const collectList = () => {
      request.get('/system/collect/list').then(res => {
        let list = [];
        val.value = res[0].collectId;
        updateData.value = res[0];
        for (let i = 0; i < res.length; i++) {
          list.push({
            text: res[i].collectName,
            value: res[i].collectId,
            memo: res[i].memo
          });
        }
        options.value = list;
        matchList(res[0].collectId);
      });
    };
    const subMatchList = ref([]);
    const matchList = value => {
      request.get('/system/userCollect/list?collectId=' + value).then(res => {
        for (let i = 0; i < res.length; i++) {
          res[i].collapseState = false;
          res[i].note = {
            content: ""
          };
          let str = res[i].matchDate.split("-");
          res[i].monthDay = str[1] + "-" + str[2];
          res[i].background = "background: #" + res[i].backColor;
        }
        subMatchList.value = res;
        umList();
      });
    };
    async function createAction() {
      actions[await Dialog('是否删除当前收藏夹')]();
    }
    const actions = {
      confirm: () => deletCollect(),
      cancel: () => () => {},
      close: () => () => {}
    };
    const deletCollect = () => {
      request.delete('/system/collect/' + val.value).then(res => {
        collectList();
      });
    };
    function cancel(data) {
      let json = {
        collectId: val.value,
        matchId: data.matchId
      };
      request.post('/system/userCollect/cancel', json).then(res => {
        if (res.code == 200) {
          successNotify("取消收藏");
          matchList(val.value);
        }
      });
    }
    const umList = async () => {
      let list = subMatchList.value;
      let matchIds = [];
      for (let j = 0; j < list.length; j++) {
        let subMatch = list[j];
        matchIds = matchIds.concat(subMatch.matchId);
      }
      const response = await service.get('/system/note/umList', {
        matchIds: matchIds
      });
      if (response.code == 200) {
        let rows = response.rows;
        for (let j = 0; j < list.length; j++) {
          let subMatch = list[j];
          for (let i = 0; i < rows.length; i++) {
            if (rows[i].matchId == subMatch.matchId) {
              subMatch.note = rows[i];
            }
          }
        }
      } else {
        timeNotify(response.msg);
      }
    };

    /**
     * 笔记保存
     * @param {*} event 
     */
    const noteSave = async (matchId, note) => {
      console.log(matchId, note);
      note.matchId = matchId;
      // 发送登录请求
      const response = await service.post('/system/note/save', note);
      if (response.code == 200) {
        //假设响应中包含一个token字段，我们将其保存到localStorage中
        successNotify("保存成功");
        await umList();
      } else {
        timeNotify(response.msg);
      }
    };

    /**
     * 折叠面板触发
     * @param {*} val 
     */
    const handleChange = async val => {
      let list = subMatchList.value;
      for (let j = 0; j < list.length; j++) {
        let subMatch = list[j];
        if (val.includes(subMatch.matchId)) {
          subMatch.collapseState = true;
        } else {
          subMatch.collapseState = false;
        }
      }
    };
    const modalRef = ref(null);
    const clickInfo = matchId => {
      modalRef.value.show(matchId);
    };
    const modalHeatRef = ref(null);
    const clickHeat = matchId => {
      modalHeatRef.value.show(matchId);
    };
    const modalListRef = ref(null);
    const clickList = matchId => {
      modalListRef.value.show(matchId);
    };
    const modalTtgRef = ref(null);
    const clickTtg = matchId => {
      modalTtgRef.value.show(matchId);
    };
    const modalBSRef = ref(null);
    const clickBS = matchId => {
      modalBSRef.value.show(matchId);
    };
    const modalMoneyRef = ref(null);
    const clickMoney = matchId => {
      modalMoneyRef.value.show(matchId);
    };

    /**
     * 初始化
     */
    const init = () => {
      collectList();
    };
    onMounted(init);
    return (_ctx, _cache) => {
      const _component_nut_menu_item = _resolveComponent("nut-menu-item");
      const _component_nut_menu = _resolveComponent("nut-menu");
      const _component_var_col = __unplugin_components_0;
      const _component_var_row = __unplugin_components_1;
      const _component_var_icon = __unplugin_components_2;
      const _component_var_button = __unplugin_components_3;
      const _component_var_input = __unplugin_components_4;
      const _component_var_collapse_item = __unplugin_components_5;
      const _component_var_collapse = __unplugin_components_6;
      const _component_nut_input = _resolveComponent("nut-input");
      const _component_nut_form_item = _resolveComponent("nut-form-item");
      const _component_nut_button = _resolveComponent("nut-button");
      const _component_nut_space = _resolveComponent("nut-space");
      const _component_nut_form = _resolveComponent("nut-form");
      const _component_nut_popup = _resolveComponent("nut-popup");
      const _component_var_fab = __unplugin_components_7;
      return _openBlock(), _createElementBlock("div", null, [_createVNode(AnalyzeHeatView, {
        ref_key: "modalHeatRef",
        ref: modalHeatRef
      }, null, 512), _createVNode(AnalyzeView, {
        ref_key: "modalRef",
        ref: modalRef
      }, null, 512), _createVNode(AnalyzeList, {
        ref_key: "modalListRef",
        ref: modalListRef
      }, null, 512), _createVNode(AnalyzeTtgView, {
        ref_key: "modalTtgRef",
        ref: modalTtgRef
      }, null, 512), _createVNode(AnalyzeBSView, {
        ref_key: "modalBSRef",
        ref: modalBSRef
      }, null, 512), _createVNode(AnalyzeMoney, {
        ref_key: "modalMoneyRef",
        ref: modalMoneyRef
      }, null, 512), _createVNode(_component_nut_menu, null, {
        default: _withCtx(() => [_createVNode(_component_nut_menu_item, {
          modelValue: val.value,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => val.value = $event),
          cols: 2,
          options: options.value,
          onChange: menuChange
        }, null, 8, ["modelValue", "options"])]),
        _: 1
      }), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(subMatchList.value, (item, index) => {
        return _openBlock(), _createBlock(_component_var_collapse, {
          modelValue: value.value,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => value.value = $event),
          offset: false,
          onChange: handleChange,
          key: index
        }, {
          default: _withCtx(() => [_createVNode(_component_var_collapse_item, {
            name: item.matchId
          }, {
            title: _withCtx(() => [_createElementVNode("div", {
              style: {
                "width": "320px"
              },
              id: item.matchId
            }, [_createVNode(_component_var_row, null, {
              default: _withCtx(() => [_createVNode(_component_var_col, {
                span: 12,
                offset: 12
              }, {
                default: _withCtx(() => [_createElementVNode("div", _hoisted_2, [item.singleHad ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(item.matchNumStr), 1)) : (_openBlock(), _createElementBlock(_Fragment, {
                  key: 1
                }, [_createTextVNode(_toDisplayString(item.matchNumStr), 1)], 64))])]),
                _: 2
              }, 1024), _createVNode(_component_var_col, {
                span: 4
              }, {
                default: _withCtx(() => [_createElementVNode("div", _hoisted_4, [_createVNode(_component_var_row, null, {
                  default: _withCtx(() => [_createVNode(_component_var_col, {
                    span: 24
                  }, {
                    default: _withCtx(() => [_createElementVNode("div", {
                      class: "tag",
                      style: _normalizeStyle(item.background)
                    }, _toDisplayString(item.leagueAbbName), 5)]),
                    _: 2
                  }, 1024)]),
                  _: 2
                }, 1024), _createVNode(_component_var_row, null, {
                  default: _withCtx(() => [_createVNode(_component_var_col, {
                    span: 24
                  }, {
                    default: _withCtx(() => [_createElementVNode("div", _hoisted_5, _toDisplayString(item.monthDay), 1)]),
                    _: 2
                  }, 1024)]),
                  _: 2
                }, 1024), _createVNode(_component_var_row, null, {
                  default: _withCtx(() => [_createVNode(_component_var_col, {
                    span: 24
                  }, {
                    default: _withCtx(() => [_createElementVNode("div", _hoisted_6, _toDisplayString(item.matchTime), 1)]),
                    _: 2
                  }, 1024)]),
                  _: 2
                }, 1024)])]),
                _: 2
              }, 1024), _createVNode(_component_var_col, {
                span: 8
              }, {
                default: _withCtx(() => [_createElementVNode("div", _hoisted_7, [_createElementVNode("div", _hoisted_8, _toDisplayString(item.homeTeamAbbName), 1), item.homeRank != null && item.homeRank != '' ? (_openBlock(), _createElementBlock("div", _hoisted_9, "[" + _toDisplayString(item.homeRank) + "]", 1)) : _createCommentVNode("", true)])]),
                _: 2
              }, 1024), _createVNode(_component_var_col, {
                span: 4
              }, {
                default: _withCtx(() => [_createElementVNode("div", _hoisted_10, [_createVNode(_component_var_row, null, {
                  default: _withCtx(() => [_createVNode(_component_var_col, {
                    span: 24
                  }, {
                    default: _withCtx(() => [item.sectionsNo999 != '' && item.sectionsNo999 != null ? (_openBlock(), _createElementBlock("div", _hoisted_11, _toDisplayString(item.sectionsNo999), 1)) : _createCommentVNode("", true)]),
                    _: 2
                  }, 1024)]),
                  _: 2
                }, 1024), _createVNode(_component_var_row, null, {
                  default: _withCtx(() => [_createVNode(_component_var_col, {
                    span: 24
                  }, {
                    default: _withCtx(() => [item.sectionsNo999 == '' || item.sectionsNo999 == null ? (_openBlock(), _createElementBlock("div", _hoisted_12, "VS")) : _createCommentVNode("", true)]),
                    _: 2
                  }, 1024)]),
                  _: 2
                }, 1024), _createVNode(_component_var_row, null, {
                  default: _withCtx(() => [_createVNode(_component_var_col, {
                    span: 24
                  }, {
                    default: _withCtx(() => [item.sectionsNo1 != '' && item.sectionsNo1 != null ? (_openBlock(), _createElementBlock("div", _hoisted_13, "半场" + _toDisplayString(item.sectionsNo1), 1)) : _createCommentVNode("", true)]),
                    _: 2
                  }, 1024)]),
                  _: 2
                }, 1024)])]),
                _: 2
              }, 1024), _createVNode(_component_var_col, {
                span: 8
              }, {
                default: _withCtx(() => [_createElementVNode("div", _hoisted_14, [_createElementVNode("div", _hoisted_15, _toDisplayString(item.awayTeamAbbName), 1), item.awayRank != null && item.awayRank != '' ? (_openBlock(), _createElementBlock("div", _hoisted_16, "[" + _toDisplayString(item.awayRank) + "]", 1)) : _createCommentVNode("", true)])]),
                _: 2
              }, 1024)]),
              _: 2
            }, 1024)], 8, _hoisted_1)]),
            icon: _withCtx(() => [_createElementVNode("div", _hoisted_17, _toDisplayString(item.matchStatusName), 1), !item.collapseState ? (_openBlock(), _createElementBlock("div", _hoisted_18, [_createVNode(_component_var_icon, {
              name: "chevron-down"
            })])) : _createCommentVNode("", true), item.collapseState ? (_openBlock(), _createElementBlock("div", _hoisted_19, [_createVNode(_component_var_icon, {
              name: "chevron-up"
            })])) : _createCommentVNode("", true)]),
            default: _withCtx(() => [_createElementVNode("div", _hoisted_20, [_createElementVNode("div", _hoisted_21, [_createVNode(_component_var_row, null, {
              default: _withCtx(() => [_createVNode(_component_var_col, {
                span: 4
              }, {
                default: _withCtx(() => [_createVNode(_component_var_button, {
                  type: "danger",
                  onClick: $event => cancel(item)
                }, {
                  default: _withCtx(() => [_createVNode(_component_var_icon, {
                    name: "window-close"
                  })]),
                  _: 2
                }, 1032, ["onClick"])]),
                _: 2
              }, 1024), _createVNode(_component_var_col, {
                span: 16
              }, {
                default: _withCtx(() => [_createElementVNode("div", _hoisted_22, [_createVNode(_component_var_input, {
                  variant: "outlined",
                  placeholder: "笔记",
                  clearable: "",
                  size: "small",
                  modelValue: item.note.content,
                  "onUpdate:modelValue": $event => item.note.content = $event
                }, null, 8, ["modelValue", "onUpdate:modelValue"])])]),
                _: 2
              }, 1024), _createVNode(_component_var_col, {
                span: 4
              }, {
                default: _withCtx(() => [_createVNode(_component_var_button, {
                  type: "info",
                  onClick: $event => noteSave(item.matchId, item.note)
                }, {
                  default: _withCtx(() => [_createTextVNode("保存")]),
                  _: 2
                }, 1032, ["onClick"])]),
                _: 2
              }, 1024)]),
              _: 2
            }, 1024)]), fullUser.value && item.matchStatus != '11' || item.matchStatus == '11' ? (_openBlock(), _createBlock(_component_var_row, {
              key: 0,
              gutter: [10, 10]
            }, {
              default: _withCtx(() => [_createVNode(_component_var_col, {
                span: 8
              }, {
                default: _withCtx(() => [_createElementVNode("div", _hoisted_23, [_createVNode(_component_var_button, {
                  type: "danger",
                  onClick: $event => clickHeat(item.matchId)
                }, {
                  default: _withCtx(() => [_createTextVNode("热度")]),
                  _: 2
                }, 1032, ["onClick"])])]),
                _: 2
              }, 1024), _createVNode(_component_var_col, {
                span: 8
              }, {
                default: _withCtx(() => [_createElementVNode("div", _hoisted_24, [_createVNode(_component_var_button, {
                  type: "success",
                  onClick: $event => clickList(item.matchId)
                }, {
                  default: _withCtx(() => [_createTextVNode("历史")]),
                  _: 2
                }, 1032, ["onClick"])])]),
                _: 2
              }, 1024), _createVNode(_component_var_col, {
                span: 8
              }, {
                default: _withCtx(() => [_createElementVNode("div", _hoisted_25, [_createVNode(_component_var_button, {
                  type: "primary",
                  onClick: $event => clickInfo(item.matchId)
                }, {
                  default: _withCtx(() => [_createTextVNode("对比")]),
                  _: 2
                }, 1032, ["onClick"])])]),
                _: 2
              }, 1024), _createVNode(_component_var_col, {
                span: 8
              }, {
                default: _withCtx(() => [_createElementVNode("div", _hoisted_26, [_createVNode(_component_var_button, {
                  type: "warning",
                  onClick: $event => clickBS(item.matchId)
                }, {
                  default: _withCtx(() => [_createTextVNode("能力")]),
                  _: 2
                }, 1032, ["onClick"])])]),
                _: 2
              }, 1024), _createVNode(_component_var_col, {
                span: 8
              }, {
                default: _withCtx(() => [_createElementVNode("div", _hoisted_27, [_createVNode(_component_var_button, {
                  color: "#FF6633",
                  "text-color": "#fff",
                  onClick: $event => clickTtg(item.matchId)
                }, {
                  default: _withCtx(() => [_createTextVNode("进球")]),
                  _: 2
                }, 1032, ["onClick"])])]),
                _: 2
              }, 1024), _createVNode(_component_var_col, {
                span: 8
              }, {
                default: _withCtx(() => [_createElementVNode("div", _hoisted_28, [_createVNode(_component_var_button, {
                  color: "#00CC00",
                  "text-color": "#fff",
                  onClick: $event => clickMoney(item.matchId)
                }, {
                  default: _withCtx(() => [_createTextVNode("资金")]),
                  _: 2
                }, 1032, ["onClick"])])]),
                _: 2
              }, 1024)]),
              _: 2
            }, 1024)) : _createCommentVNode("", true)])]),
            _: 2
          }, 1032, ["name"])]),
          _: 2
        }, 1032, ["modelValue"]);
      }), 128)), _createVNode(_component_var_fab, {
        type: _ctx.primary,
        bottom: 100
      }, {
        default: _withCtx(() => [_createVNode(_component_var_button, {
          type: "success",
          onClick: insertCollect,
          "icon-container": ""
        }, {
          default: _withCtx(() => [_createVNode(_component_var_icon, {
            name: "plus-circle-outline",
            size: 30
          })]),
          _: 1
        }), _createVNode(_component_var_button, {
          type: "warning",
          onClick: updateCollect,
          "icon-container": ""
        }, {
          default: _withCtx(() => [_createVNode(_component_var_icon, {
            name: "cog-outline",
            size: 30
          })]),
          _: 1
        }), _createVNode(_component_var_button, {
          type: "danger",
          onClick: createAction,
          "icon-container": ""
        }, {
          default: _withCtx(() => [_createVNode(_component_var_icon, {
            name: "delete",
            size: 30
          })]),
          _: 1
        }), _createVNode(_component_nut_popup, {
          visible: isShow.value,
          "onUpdate:visible": _cache[4] || (_cache[4] = $event => isShow.value = $event),
          "z-index": 10,
          closeable: "",
          style: {
            padding: '50px 10px',
            width: '90%',
            overflow: 'auto'
          }
        }, {
          default: _withCtx(() => [_createVNode(_component_nut_form, {
            ref_key: "JcCollectRef",
            ref: JcCollectRef,
            "model-value": JcCollectData.value,
            rules: {
              collectName: [{
                required: true,
                message: 'collectName'
              }]
            }
          }, {
            default: _withCtx(() => [_createVNode(_component_nut_form_item, {
              label: "名称",
              prop: "collectName"
            }, {
              default: _withCtx(() => [_createVNode(_component_nut_input, {
                modelValue: JcCollectData.value.collectName,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => JcCollectData.value.collectName = $event),
                placeholder: "请输名称"
              }, null, 8, ["modelValue"])]),
              _: 1
            }), _createVNode(_component_nut_form_item, {
              label: "备注",
              prop: "memo"
            }, {
              default: _withCtx(() => [_createVNode(_component_nut_input, {
                modelValue: JcCollectData.value.memo,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => JcCollectData.value.memo = $event),
                placeholder: "请输入备注"
              }, null, 8, ["modelValue"])]),
              _: 1
            }), _createVNode(_component_nut_space, {
              style: {
                "margin": "10px"
              }
            }, {
              default: _withCtx(() => [_createVNode(_component_nut_button, {
                type: "primary",
                size: "small",
                onClick: submitCollect
              }, {
                default: _withCtx(() => [_createTextVNode("提交")]),
                _: 1
              })]),
              _: 1
            })]),
            _: 1
          }, 8, ["model-value"])]),
          _: 1
        }, 8, ["visible"])]),
        _: 1
      }, 8, ["type"])]);
    };
  }
};