// request.js
import axios from 'axios';

// 创建axios实例
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL, // api的base_url
  timeout: 5000 // 请求超时时间
});

// 请求拦截器
service.interceptors.request.use(
  config => {
    // 在发送请求之前做些什么
    // 例如，添加请求头、身份验证等
    const token = localStorage.getItem('token'); // 假设你从localStorage获取token
    if (token) {
      // 让每个请求携带自定义token
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  error => {
    // 处理请求错误
    console.error(error); // for debug
    Promise.reject(error);
  }
);

// 响应拦截器
service.interceptors.response.use(
  response => {
    // 如果返回的状态码为200，说明接口请求成功，可以正常拿到数据
    // 否则的话抛出错误
    const res = response.data;
    if (response.status === 200) {
      return res;
    } else {
      return Promise.reject(res);
    }
  },
  error => {
    // 处理响应错误
    console.error('Error', error); // for debug
    // 根据返回的状态码判断是什么错误，并进行相应处理
    if (error.response.status) {
      switch (error.response.status) {
        case 401:
          // 未登录或登录失效，跳转登录页面
          router.replace('/login');
          break;
        case 403:
          // 没有权限，跳转无权限页面
          router.replace('/no-access');
          break;
        case 404:
          // 请求不存在，跳转404页面
          router.replace('/404');
          break;
        // 其他错误，直接抛出
        default:
          return Promise.reject(error.response);
      }
    }
    return Promise.reject(error);
  }
);

export default service;