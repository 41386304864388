import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-6a8836a7"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "content title"
};
import { ref } from 'vue';
import { showNotify } from '@nutui/nutui';
import axios from 'axios';
import HeatHadView from './HeatHadView.vue';
export default {
  __name: 'AnalyzeMoney',
  setup(__props, {
    expose: __expose
  }) {
    const timeNotify = msg => {
      showNotify.warn(msg, {
        duration: 2000
      });
    };
    const title = ref('');
    const isVisible = ref(false);
    // 显示弹出框
    const show = matchId => {
      isVisible.value = true;
      getFixedBonusV1(matchId);
    };
    const oddsHistory = ref({});
    const heatRef = ref(null);
    const goalLine = ref("");
    const getFixedBonusV1 = async matchId => {
      try {
        const response = await axios.get('https://webapi.sporttery.cn/gateway/jc/football/getFixedBonusV1.qry?clientCode=3001&matchId=' + matchId);
        let data = response.data;
        if (data.success) {
          oddsHistory.value = data.value.oddsHistory;
          goalLine.value = oddsHistory.value.hhadList[0].goalLine;
          title.value = oddsHistory.value.homeTeamAbbName + ' VS ' + oddsHistory.value.awayTeamAbbName;
          if (data.value.sectionsNo999 != '') {
            title.value = oddsHistory.value.homeTeamAbbName + ' ' + data.value.sectionsNo999 + ' ' + oddsHistory.value.awayTeamAbbName;
          }
          heatRef.value.pushMatch(oddsHistory.value);
        } else {
          timeNotify(data.errorMessage);
        }
      } catch (error) {
        // 处理错误
        console.error(error);
      }
    };

    // 关闭弹出框
    const close = () => {
      isVisible.value = false;
    };

    // 暴露给外部使用的函数
    __expose({
      show,
      close
    });
    return (_ctx, _cache) => {
      const _component_nut_col = _resolveComponent("nut-col");
      const _component_nut_row = _resolveComponent("nut-row");
      const _component_nut_popup = _resolveComponent("nut-popup");
      return _openBlock(), _createBlock(_component_nut_popup, {
        visible: isVisible.value,
        "onUpdate:visible": _cache[0] || (_cache[0] = $event => isVisible.value = $event),
        closeable: "",
        style: {
          padding: '30px 10px',
          height: '85%',
          width: '90%',
          overflow: 'auto'
        }
      }, {
        default: _withCtx(() => [_createVNode(_component_nut_row, null, {
          default: _withCtx(() => [_createVNode(_component_nut_col, {
            span: 24
          }, {
            default: _withCtx(() => [_createElementVNode("div", _hoisted_1, "(" + _toDisplayString(goalLine.value) + ")" + _toDisplayString(title.value), 1)]),
            _: 1
          })]),
          _: 1
        }), _createVNode(HeatHadView, {
          ref_key: "heatRef",
          ref: heatRef
        }, null, 512)]),
        _: 1
      }, 8, ["visible"]);
    };
  }
};