import { createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
  class: "content title"
};
const _hoisted_2 = {
  style: {
    "padding": "10px 2px"
  }
};
const _hoisted_3 = {
  style: {
    "padding": "10px 2px"
  }
};
const _hoisted_4 = {
  class: "content fontCoarse"
};
const _hoisted_5 = {
  class: "content fontCoarse"
};
import { ref } from 'vue';
import { showNotify } from '@nutui/nutui';
import axios from 'axios';
import { PoissonCalculation } from './calculation.js';
import LoadingView from './LoadingView.vue';
export default {
  __name: 'AnalyzeBSView',
  setup(__props, {
    expose: __expose
  }) {
    const timeNotify = msg => {
      showNotify.warn(msg, {
        duration: 2000
      });
    };
    const title = ref('');
    const isListVisible = ref(false);
    const tournamentFlag = ref(false);
    const homeAwayFlag = ref(false);
    const termLimits = ref(6);
    const tournamentFlagInt = ref(0);
    const homeAwayFlagInt = ref(0);
    const id = ref('');
    const loadingRef = ref(null);
    const updateTermLimits = val => {
      getFixedBonusV1(id.value);
    };
    const text = ref('该方法为计算主客近6场战绩所得进球数概率和比分概率，不可完全依照提示。可根据机构盘口和体彩（胜平负，比分，进球数）来进行判断分析');
    // 显示弹出框
    const show = matchId => {
      isListVisible.value = true;
      getFixedBonusV1(matchId);
    };
    const columns = ref([{
      title: '进球数',
      key: 'goal'
    }, {
      title: '概率',
      key: 'probability'
    }]);
    const tournamentChange = value => {
      if (value) {
        tournamentFlagInt.value = 1;
      } else {
        tournamentFlagInt.value = 0;
      }
      getFixedBonusV1(id.value);
    };
    const homeAwayFlagChange = value => {
      if (value) {
        homeAwayFlagInt.value = 1;
      } else {
        homeAwayFlagInt.value = 0;
      }
      getFixedBonusV1(id.value);
    };
    const oddsHistory = ref({});
    const bosong = ref({});
    const bosongS = ref({});
    const getFixedBonusV1 = async matchId => {
      try {
        if (matchId == undefined || matchId == '') {
          return;
        }
        loadingRef.value.show();
        id.value = matchId;
        const response = await axios.get('https://webapi.sporttery.cn/gateway/jc/football/getFixedBonusV1.qry?clientCode=3001&matchId=' + matchId);
        let data = response.data;
        if (data.success) {
          oddsHistory.value = data.value.oddsHistory;
          title.value = oddsHistory.value.homeTeamAbbName + ' VS ' + oddsHistory.value.awayTeamAbbName;
          const response2 = await axios.get('https://webapi.sporttery.cn/gateway/uniform/football/getMatchResultV1.qry?sportteryMatchId=' + matchId + '&termLimits=' + termLimits.value + '&tournamentFlag=' + tournamentFlagInt.value + '&homeAwayFlag=' + homeAwayFlagInt.value);
          let data2 = response2.data;
          let value2 = data2.value;
          if (data2.success) {
            bosong.value = PoissonCalculation(value2.home.statistics.goalCnt, value2.home.statistics.totalLegCnt, value2.away.statistics.goalCnt, value2.away.statistics.totalLegCnt);
            bosongS.value = PoissonCalculation(value2.home.statistics.lossGoalCnt, value2.home.statistics.totalLegCnt, value2.away.statistics.lossGoalCnt, value2.away.statistics.totalLegCnt);
            loadingRef.value.close();
          } else {
            loadingRef.value.close();
            timeNotify(data2.errorMessage);
          }
        } else {
          loadingRef.value.close();
          timeNotify(data.errorMessage);
        }
      } catch (error) {
        // 处理错误
        console.error(error);
        loadingRef.value.close();
      }
    };

    // 关闭弹出框
    const close = () => {
      isVisible.value = false;
    };

    // 暴露给外部使用的函数
    __expose({
      show,
      close
    });
    return (_ctx, _cache) => {
      const _component_nut_col = _resolveComponent("nut-col");
      const _component_nut_row = _resolveComponent("nut-row");
      const _component_nut_divider = _resolveComponent("nut-divider");
      const _component_nut_noticebar = _resolveComponent("nut-noticebar");
      const _component_nut_switch = _resolveComponent("nut-switch");
      const _component_nut_input = _resolveComponent("nut-input");
      const _component_nut_table = _resolveComponent("nut-table");
      const _component_nut_popup = _resolveComponent("nut-popup");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(LoadingView, {
        ref_key: "loadingRef",
        ref: loadingRef
      }, null, 512), _createVNode(_component_nut_popup, {
        visible: isListVisible.value,
        "onUpdate:visible": _cache[3] || (_cache[3] = $event => isListVisible.value = $event),
        closeable: "",
        style: {
          padding: '30px 10px',
          height: '85%',
          width: '90%',
          overflow: 'auto'
        }
      }, {
        default: _withCtx(() => [_createVNode(_component_nut_row, null, {
          default: _withCtx(() => [_createVNode(_component_nut_col, {
            span: 24
          }, {
            default: _withCtx(() => [_createElementVNode("div", _hoisted_1, _toDisplayString(title.value), 1)]),
            _: 1
          })]),
          _: 1
        }), _createVNode(_component_nut_divider, {
          "content-position": "left"
        }, {
          default: _withCtx(() => [_createTextVNode("能力计算")]),
          _: 1
        }), _createVNode(_component_nut_noticebar, {
          text: text.value,
          wrapable: ""
        }, null, 8, ["text"]), _createVNode(_component_nut_row, null, {
          default: _withCtx(() => [_createVNode(_component_nut_col, {
            span: 8
          }, {
            default: _withCtx(() => [_createElementVNode("div", _hoisted_2, [_createTextVNode(" 相同赛制："), _createVNode(_component_nut_switch, {
              modelValue: tournamentFlag.value,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => tournamentFlag.value = $event),
              onChange: tournamentChange
            }, null, 8, ["modelValue"])])]),
            _: 1
          }), _createVNode(_component_nut_col, {
            span: 8
          }, {
            default: _withCtx(() => [_createElementVNode("div", _hoisted_3, [_createTextVNode(" 主客同场："), _createVNode(_component_nut_switch, {
              modelValue: homeAwayFlag.value,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => homeAwayFlag.value = $event),
              onChange: homeAwayFlagChange
            }, null, 8, ["modelValue"])])]),
            _: 1
          }), _createVNode(_component_nut_col, {
            span: 8
          }, {
            default: _withCtx(() => [_createVNode(_component_nut_input, {
              modelValue: termLimits.value,
              "onUpdate:modelValue": [_cache[2] || (_cache[2] = $event => termLimits.value = $event), updateTermLimits],
              type: "number"
            }, {
              left: _withCtx(() => [_createTextVNode(" 近 ")]),
              right: _withCtx(() => [_createTextVNode(" 场 ")]),
              _: 1
            }, 8, ["modelValue"])]),
            _: 1
          })]),
          _: 1
        }), _createVNode(_component_nut_table, {
          columns: columns.value,
          data: bosong.value.totalGoals
        }, null, 8, ["columns", "data"]), _createVNode(_component_nut_divider, {
          "content-position": "left"
        }, {
          default: _withCtx(() => [_createTextVNode("进球能力")]),
          _: 1
        }), _createVNode(_component_nut_row, null, {
          default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(bosong.value.score, (index, key) => {
            return _openBlock(), _createBlock(_component_nut_col, {
              span: 8,
              key: key
            }, {
              default: _withCtx(() => [_createElementVNode("div", _hoisted_4, _toDisplayString(index), 1)]),
              _: 2
            }, 1024);
          }), 128))]),
          _: 1
        }), _createVNode(_component_nut_divider, {
          "content-position": "left"
        }, {
          default: _withCtx(() => [_createTextVNode("失球能力")]),
          _: 1
        }), _createVNode(_component_nut_row, null, {
          default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(bosongS.value.score, (index, key) => {
            return _openBlock(), _createBlock(_component_nut_col, {
              span: 8,
              key: key
            }, {
              default: _withCtx(() => [_createElementVNode("div", _hoisted_5, _toDisplayString(index), 1)]),
              _: 2
            }, 1024);
          }), 128))]),
          _: 1
        })]),
        _: 1
      }, 8, ["visible"])], 64);
    };
  }
};