import { createApp } from 'vue'
import App from './App.vue'
import NutUI from "@nutui/nutui";
import "@nutui/nutui/dist/style.css";
import '@varlet/ui/es/style'
import ArcoVue from "@arco-design/web-vue";
import ArcoVueIcon from "@arco-design/web-vue/es/icon";
import "@arco-design/web-vue/dist/arco.css";
import store from './store'
import router from './router';
import { Tabbar, TabbarItem } from '@nutui/nutui'
import { PullRefresh } from '@nutui/nutui'
import { Collapse, CollapseItem } from '@nutui/nutui'
import { Divider } from '@nutui/nutui'
import { Table } from '@nutui/nutui'
// 访问环境变量中的基础URL
const baseUrl = process.env.VUE_APP_BASE_URL

const app = createApp(App)
app.use(router)
app.use(store)
app.use(NutUI)
app.use(Tabbar)
app.use(TabbarItem)
app.use(PullRefresh)
app.use(Collapse)
app.use(CollapseItem)
app.use(Divider)
app.use(Table)
app.use(ArcoVueIcon);
app.use(ArcoVue);
app.mount('#app')
// 现在你可以在你的应用程序中使用这个基础URL
app.config.globalProperties.$baseUrl = baseUrl
