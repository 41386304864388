import { createStore } from 'vuex'

const store = createStore({
	state: {
		isLoggedIn: false,
		user: null,
		fullUser: false
	},
	mutations: {
		setLoginStatus (state, status) {
			state.isLoggedIn = status
		},
		setUser (state, user) {
			state.user = user
		},
		setfullUser (state, fullUser) {
			state.fullUser = fullUser
		}
	},
	actions: {
		login ({ commit }, user) {
			// 这里是模拟登录过程，实际项目中应该发送请求到登录接口
			// 假设登录成功，设置登录状态和用户信息
			commit('setLoginStatus', true)
			commit('setUser', user.user)
			commit('setfullUser', user.fullUser)
		},
		logout ({ commit }) {
			// 这里是模拟注销过程，实际项目中应该发送请求到注销接口
			// 注销成功后，清除登录状态和用户信息
			commit('setLoginStatus', false)
			commit('setUser', null)
			commit('setfullUser', false)
		}
	}
})

export default store;