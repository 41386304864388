import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, unref as _unref, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-48409f7a"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "center"
};
import { ref, onMounted, createVNode } from 'vue';
import { Dongdong } from '@nutui/icons-vue';
import { showNotify, showDialog } from '@nutui/nutui';
import service from '@/utils/request';
import request from '@/utils/request';
import { useStore } from 'vuex';
export default {
  __name: 'FullTimeView',
  setup(__props) {
    const store = useStore();
    const timeNotify = msg => {
      showNotify.warn(msg, {
        duration: 2000
      });
    };
    const successNotify = msg => {
      showNotify.success(msg, {
        duration: 2000
      });
    };
    const text = ref('使用鲸豆购买会员时长');
    const cardList = ref([]);
    const getTimeList = () => {
      request.get('/getCardList').then(res => {
        console.log(res);
        if (res.code == 200) {
          cardList.value = res.cardList;
        } else {
          console.log(1);
        }
      });
    };
    const baseClick = card => {
      let text = "购买会员时长：" + card.name;
      showDialog({
        title: '会员时长购买',
        content: createVNode('span', {
          style: {
            color: 'red'
          }
        }, text),
        onCancel,
        onOk: () => {
          onOk(card);
        }
      });
    };
    const onOk = card => {
      console.log('确认购买');
      openCard(card.cardId);
    };
    const openCard = async cardId => {
      // 发送登录请求
      const response = await service.post('/openCard', {
        cardId: cardId
      });
      if (response.code == 200) {
        // 假设响应中包含一个token字段，我们将其保存到localStorage中
        successNotify("购买成功");
        getInfo();
      } else {
        timeNotify(response.msg);
      }
    };
    const getInfo = () => {
      request.get('/getInfo').then(res => {
        if (res.code == 200) {
          // 登录成功，更新Vuex状态
          store.dispatch('login', res);
        }
      });
    };
    const onCancel = () => {
      console.log('取消购买');
    };
    onMounted(getTimeList);
    return (_ctx, _cache) => {
      const _component_nut_noticebar = _resolveComponent("nut-noticebar");
      const _component_nut_grid_item = _resolveComponent("nut-grid-item");
      const _component_nut_grid = _resolveComponent("nut-grid");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_nut_noticebar, {
        text: text.value,
        wrapable: ""
      }, null, 8, ["text"]), _createElementVNode("div", _hoisted_1, [_createVNode(_component_nut_grid, {
        gutter: 10,
        "column-num": 2
      }, {
        default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(cardList.value, (card, index) => {
          return _openBlock(), _createBlock(_component_nut_grid_item, {
            text: card.name,
            key: index,
            onClick: $event => baseClick(card)
          }, {
            default: _withCtx(() => [_createTextVNode(_toDisplayString(card.amount) + " ", 1), _createVNode(_unref(Dongdong))]),
            _: 2
          }, 1032, ["text", "onClick"]);
        }), 128))]),
        _: 1
      })])], 64);
    };
  }
};