import CryptoJS from 'crypto-js'


export function decryptData (encrypted) {
	try {
		const bytes = CryptoJS.enc.Base64.parse(encrypted);
		const secretKey = CryptoJS.enc.Utf8.parse('1234567890123456');
		const decrypted = CryptoJS.AES.decrypt(
			{ ciphertext: bytes },
			secretKey,
			{
				mode: CryptoJS.mode.ECB,
				padding: CryptoJS.pad.Pkcs7
			}
		);
		const decryptedText = decrypted.toString(CryptoJS.enc.Utf8);
		return JSON.parse(decryptedText);
	} catch (error) {
		console.error('Decryption failed:', error);
		throw error; // 重新抛出错误，以便上层调用者可以处理
	}
}

export function computeDiscrepancy (array) {
	if (!Array.isArray(array) || array.length === 0) {
		return 0;
	}
	const mean = array.reduce((sum, value) => sum + value, 0) / array.length;
	return array.reduce((discrepancy, value) => {
		return discrepancy + Math.abs(value - mean);
	}, 0);
}


export function count (num1, num2, symbol) {
	let str1 = num1.toString();
	let str2 = num2.toString();
	let result,
		len1,
		len2,
		step;
	try {
		len1 = str1.split('.')[1].length
	} catch (e) {
		len1 = 0
	};
	try {
		len2 = str2.split('.')[1].length
	} catch (e) {
		len2 = 0
	};
	step = Math.pow(10, Math.max(len1, len2));
	let a = parseInt(num1 * step);
	let b = parseInt(num2 * step);
	switch (symbol) {
		case '+':
			result = (a + b) / step
			break;
		case "-":
			result = (a - b) / step
			break;
		case "*":
			result = (a * b) / step / step
			break;
		case "/":
			result = a / b
			break;
		default:
			break;
	}
	return result;
}

export function getCrsReturnRate (crs) {
	let sum = 0;
	sum = count(sum, count(1, crs.s00s00, '/'), '+'); sum = count(sum, count(1, crs.s01s01, '/'), '+'); sum = count(sum, count(1, crs.s02s02, '/'), '+'); sum = count(sum, count(1, crs.s03s03, '/'), '+'); sum = count(sum, count(1, crs['s-1sd'], '/'), '+');
	sum = count(sum, count(1, crs.s01s00, '/'), '+'); sum = count(sum, count(1, crs.s02s00, '/'), '+'); sum = count(sum, count(1, crs.s02s01, '/'), '+'); sum = count(sum, count(1, crs.s03s00, '/'), '+'); sum = count(sum, count(1, crs.s03s01, '/'), '+');
	sum = count(sum, count(1, crs.s03s02, '/'), '+'); sum = count(sum, count(1, crs.s04s00, '/'), '+'); sum = count(sum, count(1, crs.s04s01, '/'), '+'); sum = count(sum, count(1, crs.s04s02, '/'), '+'); sum = count(sum, count(1, crs.s05s00, '/'), '+');
	sum = count(sum, count(1, crs.s05s01, '/'), '+'); sum = count(sum, count(1, crs.s05s02, '/'), '+'); sum = count(sum, count(1, crs['s-1sh'], '/'), '+');
	sum = count(sum, count(1, crs.s00s01, '/'), '+'); sum = count(sum, count(1, crs.s00s02, '/'), '+'); sum = count(sum, count(1, crs.s01s02, '/'), '+'); sum = count(sum, count(1, crs.s00s03, '/'), '+'); sum = count(sum, count(1, crs.s01s03, '/'), '+');
	sum = count(sum, count(1, crs.s02s03, '/'), '+'); sum = count(sum, count(1, crs.s00s04, '/'), '+'); sum = count(sum, count(1, crs.s01s04, '/'), '+'); sum = count(sum, count(1, crs.s02s04, '/'), '+'); sum = count(sum, count(1, crs.s00s05, '/'), '+');
	sum = count(sum, count(1, crs.s01s05, '/'), '+'); sum = count(sum, count(1, crs.s02s05, '/'), '+'); sum = count(sum, count(1, crs['s-1sa'], '/'), '+');
	let rate = count(1, sum, '/');
	return rate;
}

export function getCrsHadHRate (crs) {
	const rate = getCrsReturnRate(crs);
	let sum = count(rate, crs.s01s00, '/');
	sum = count(sum, count(rate, crs.s02s00, '/'), '+');
	sum = count(sum, count(rate, crs.s02s01, '/'), '+');
	sum = count(sum, count(rate, crs.s03s00, '/'), '+');
	sum = count(sum, count(rate, crs.s03s01, '/'), '+');
	sum = count(sum, count(rate, crs.s03s02, '/'), '+');
	sum = count(sum, count(rate, crs.s04s00, '/'), '+');
	sum = count(sum, count(rate, crs.s04s01, '/'), '+');
	sum = count(sum, count(rate, crs.s04s02, '/'), '+');
	sum = count(sum, count(rate, crs.s05s00, '/'), '+');
	sum = count(sum, count(rate, crs.s05s01, '/'), '+');
	sum = count(sum, count(rate, crs.s05s02, '/'), '+')
	sum = count(sum, count(rate, crs['s-1sh'], '/'), '+')
	return sum;
}

export function getCrsHadDRate (crs) {
	const rate = getCrsReturnRate(crs);
	let sum = count(rate, crs.s00s00, '/');
	sum = count(sum, count(rate, crs.s01s01, '/'), '+');
	sum = count(sum, count(rate, crs.s02s02, '/'), '+');
	sum = count(sum, count(rate, crs.s03s03, '/'), '+');
	sum = count(sum, count(rate, crs['s-1sd'], '/'), '+')
	return sum;
}

export function getCrsHadARate (crs) {
	return count(1, count(getCrsHadHRate(crs), getCrsHadDRate(crs), '+'), '-');
}


export function getCrsRecommend (list) {
	let firstOption = '无', secondOption = '无';
	if (list.length > 1) {
		let hL = [];
		let dL = [];
		let aL = [];
		let payoutRate = 0.88
		for (let i = 0; i < list.length; i++) {
			hL[i] = count(payoutRate, getCrsHadHRate(list[i]), '/');
			dL[i] = count(payoutRate, getCrsHadDRate(list[i]), '/');
			aL[i] = count(payoutRate, getCrsHadARate(list[i]), '/');
		}
		let hLS = computeDiscrepancy(hL);
		let dLS = computeDiscrepancy(dL);
		let aLs = computeDiscrepancy(aL);
		let numbers = [hLS, dLS, aLs];
		numbers = numbers.slice().sort((a, b) => a - b);

		if (numbers[0] == hLS) {
			firstOption = '胜';
		} else if (numbers[0] == dLS) {
			firstOption = '平';
		} else {
			firstOption = '负';
		}
		if (numbers[1] == hLS) {
			secondOption = '胜';
		} else if (numbers[1] == dLS) {
			secondOption = '平';
		} else {
			secondOption = '负';
		}
	}
	return { firstOption: firstOption, secondOption: secondOption };
}


export function getHafuReturnRate (hafu) {
	let sum = 0;
	sum = count(sum, count(1, hafu.hh, '/'), '+'); sum = count(sum, count(1, hafu.hd, '/'), '+'); sum = count(sum, count(1, hafu.ha, '/'), '+'); sum = count(sum, count(1, hafu.dh, '/'), '+');
	sum = count(sum, count(1, hafu.dd, '/'), '+'); sum = count(sum, count(1, hafu.da, '/'), '+'); sum = count(sum, count(1, hafu.ah, '/'), '+'); sum = count(sum, count(1, hafu.ad, '/'), '+');
	sum = count(sum, count(1, hafu.aa, '/'), '+');
	let rate = count(1, sum, '/');
	return rate;
}

export function getHafuHadHRate (hafu) {
	const rate = getHafuReturnRate(hafu);
	let sum = count(rate, hafu.hh, '/');
	sum = count(sum, count(rate, hafu.dh, '/'), '+');
	sum = count(sum, count(rate, hafu.ah, '/'), '+');
	return sum;
}

export function getHafuHadDRate (hafu) {
	const rate = getHafuReturnRate(hafu);
	let sum = count(rate, hafu.hd, '/');
	sum = count(sum, count(rate, hafu.dd, '/'), '+');
	sum = count(sum, count(rate, hafu.ad, '/'), '+');
	return sum;
}

export function getHafuHadARate (crs) {
	return count(1, count(getHafuHadHRate(crs), getHafuHadDRate(crs), '+'), '-');
}


export function getHafuRecommend (list) {
	let firstOption = '无', secondOption = '无';
	if (list.length > 1) {
		let hL = [];
		let dL = [];
		let aL = [];
		let payoutRate = 0.88
		for (let i = 0; i < list.length; i++) {
			hL[i] = count(payoutRate, getHafuHadHRate(list[i]), '/');
			dL[i] = count(payoutRate, getHafuHadDRate(list[i]), '/');
			aL[i] = count(payoutRate, getHafuHadARate(list[i]), '/');
		}
		let hLS = computeDiscrepancy(hL);
		let dLS = computeDiscrepancy(dL);
		let aLs = computeDiscrepancy(aL);
		let numbers = [hLS, dLS, aLs];
		numbers = numbers.slice().sort((a, b) => a - b);

		if (numbers[0] == hLS) {
			firstOption = '胜';
		} else if (numbers[0] == dLS) {
			firstOption = '平';
		} else {
			firstOption = '负';
		}
		if (numbers[1] == hLS) {
			secondOption = '胜';
		} else if (numbers[1] == dLS) {
			secondOption = '平';
		} else {
			secondOption = '负';
		}
	}
	return { firstOption: firstOption, secondOption: secondOption };
}



export function getTtgReturnRate (ttg) {
	let sum = 0;
	sum = count(sum, count(1, ttg.s0, '/'), '+'); sum = count(sum, count(1, ttg.s1, '/'), '+'); sum = count(sum, count(1, ttg.s2, '/'), '+'); sum = count(sum, count(1, ttg.s3, '/'), '+');
	sum = count(sum, count(1, ttg.s4, '/'), '+'); sum = count(sum, count(1, ttg.s5, '/'), '+'); sum = count(sum, count(1, ttg.s6, '/'), '+'); sum = count(sum, count(1, ttg.s7, '/'), '+');
	let rate = count(1, sum, '/');
	return rate;
}


/**
 * 
 * @param {进球数} ttg 
 * @param {转换值如：1.88,1.90等} num 
 */
export function getTtgConvert (ttg, num) {
	let rate = getTtgReturnRate(ttg);
	let s0Rate = count(rate, ttg.s0, '/');
	let s1Rate = count(rate, ttg.s1, '/');
	let s2Rate = count(rate, ttg.s2, '/');
	let s3Rate = count(rate, ttg.s3, '/');
	let s4Rate = count(rate, ttg.s4, '/');
	let s5Rate = count(rate, ttg.s5, '/');
	let s6Rate = count(rate, ttg.s6, '/');
	let s7Rate = count(1, count(s0Rate, count(s1Rate, count(s2Rate, count(s3Rate, count(s4Rate, count(s5Rate, s6Rate, '+'), '+'), '+'), '+'), '+'), '+'), "-");
	let Up05Rate = count(s2Rate, count(s3Rate, count(s4Rate, count(s5Rate, count(s6Rate, count(s7Rate, s1Rate, '+'), '+'), '+'), '+'), '+'), '+');
	let Up15Rate = count(s2Rate, count(s3Rate, count(s4Rate, count(s5Rate, count(s6Rate, s7Rate, '+'), '+'), '+'), '+'), '+');
	let Down15Rate = count(s0Rate, s1Rate, '+');
	let Up25Rate = count(s3Rate, count(s4Rate, count(s5Rate, count(s6Rate, s7Rate, '+'), '+'), '+'), '+');
	let Down25Rate = count(s0Rate, count(s1Rate, s2Rate, '+'), '+');
	let Up35Rate = count(s4Rate, count(s5Rate, count(s6Rate, s7Rate, '+'), '+'), '+');
	let Down35Rate = count(s0Rate, count(s1Rate, count(s2Rate, s3Rate, '+'), '+'), '+');
	let Up45Rate = count(s5Rate, count(s6Rate, s7Rate, '+'), '+');
	let Down45Rate = count(s0Rate, count(s1Rate, count(s2Rate, count(s3Rate, s4Rate, '+'), '+'), '+'), '+');
	let Up05 = count(num, Up05Rate, '*').toFixed(2);
	let Up15 = count(num, Up15Rate, '*').toFixed(2);
	let Up25 = count(num, Up25Rate, '*').toFixed(2);
	let Up35 = count(num, Up35Rate, '*').toFixed(2);
	let Up45 = count(num, Up45Rate, '*').toFixed(2);
	return {
		Up05: Up05,
		Down05: count(num, Up05, '-'),
		Up15: Up15,
		Down15: count(num, Up15, '-'),
		Up25: Up25,
		Down25: count(num, Up25, '-'),
		Up35: Up35,
		Down35: count(num, Up35, '-'),
		Up45: Up45,
		Down45: count(num, Up45, '-'),
	};
}


/**
 * 
 * @param {比分} crs 
 * @param {转换值如：1.88,1.90等} num 
 */
export function getCrsToTtgConvert (crs, num) {
	let rate = getCrsReturnRate(crs);
	let s0Rate = count(rate, crs.s00s00, '/');
	let s1Rate = count(rate, crs.s00s01, '/'); s1Rate = count(s1Rate, count(rate, crs.s01s00, '/'), '+');
	let s2Rate = count(rate, crs.s00s02, '/'); s2Rate = count(s2Rate, count(rate, crs.s02s00, '/'), '+'); s2Rate = count(s2Rate, count(rate, crs.s01s01, '/'), '+');
	let s3Rate = count(rate, crs.s00s03, '/'); s3Rate = count(s3Rate, count(rate, crs.s03s00, '/'), '+'); s3Rate = count(s3Rate, count(rate, crs.s02s01, '/'), '+'); s3Rate = count(s3Rate, count(rate, crs.s01s02, '/'), '+');
	let s4Rate = count(rate, crs.s00s04, '/'); s4Rate = count(s4Rate, count(rate, crs.s04s00, '/'), '+'); s4Rate = count(s4Rate, count(rate, crs.s03s01, '/'), '+'); s4Rate = count(s4Rate, count(rate, crs.s01s03, '/'), '+'); s4Rate = count(s4Rate, count(rate, crs.s02s02, '/'), '+');
	let s5Rate = count(rate, crs.s00s05, '/'); s5Rate = count(s5Rate, count(rate, crs.s05s00, '/'), '+'); s5Rate = count(s5Rate, count(rate, crs.s04s01, '/'), '+'); s5Rate = count(s5Rate, count(rate, crs.s01s04, '/'), '+'); s5Rate = count(s5Rate, count(rate, crs.s03s02, '/'), '+'); s5Rate = count(s5Rate, count(rate, crs.s02s03, '/'), '+');
	let s6Rate = count(rate, crs.s05s01, '/'); s6Rate = count(s6Rate, count(rate, crs.s01s05, '/'), '+'); s6Rate = count(s6Rate, count(rate, crs.s04s02, '/'), '+'); s6Rate = count(s6Rate, count(rate, crs.s02s04, '/'), '+'); s6Rate = count(s6Rate, count(rate, crs.s03s03, '/'), '+');
	let s7Rate = count(1, count(s0Rate, count(s1Rate, count(s2Rate, count(s3Rate, count(s4Rate, count(s5Rate, s6Rate, '+'), '+'), '+'), '+'), '+'), '+'), "-");
	let Up05Rate = count(s2Rate, count(s3Rate, count(s4Rate, count(s5Rate, count(s6Rate, count(s7Rate, s1Rate, '+'), '+'), '+'), '+'), '+'), '+');
	let Up15Rate = count(s2Rate, count(s3Rate, count(s4Rate, count(s5Rate, count(s6Rate, s7Rate, '+'), '+'), '+'), '+'), '+');
	let Down15Rate = count(s0Rate, s1Rate, '+');
	let Up25Rate = count(s3Rate, count(s4Rate, count(s5Rate, count(s6Rate, s7Rate, '+'), '+'), '+'), '+');
	let Down25Rate = count(s0Rate, count(s1Rate, s2Rate, '+'), '+');
	let Up35Rate = count(s4Rate, count(s5Rate, count(s6Rate, s7Rate, '+'), '+'), '+');
	let Down35Rate = count(s0Rate, count(s1Rate, count(s2Rate, s3Rate, '+'), '+'), '+');
	let Up45Rate = count(s5Rate, count(s6Rate, s7Rate, '+'), '+');
	let Down45Rate = count(s0Rate, count(s1Rate, count(s2Rate, count(s3Rate, s4Rate, '+'), '+'), '+'), '+');
	let Up05 = count(num, Up05Rate, '*').toFixed(2);
	let Up15 = count(num, Up15Rate, '*').toFixed(2);
	let Up25 = count(num, Up25Rate, '*').toFixed(2);
	let Up35 = count(num, Up35Rate, '*').toFixed(2);
	let Up45 = count(num, Up45Rate, '*').toFixed(2);
	return {
		Up05: Up05,
		Down05: count(num, Up05, '-'),
		Up15: Up15,
		Down15: count(num, Up15, '-'),
		Up25: Up25,
		Down25: count(num, Up25, '-'),
		Up35: Up35,
		Down35: count(num, Up35, '-'),
		Up45: Up45,
		Down45: count(num, Up45, '-'),
	};
}


function factorial (n) {
	// 计算阶乘
	let result = 1;
	for (let i = 2; i <= n; i++) {
		result *= i;
	}
	return result;
}

function poissonDistribution (lambda, k) {
	// 计算泊松分布的概率质量函数
	if (k < 0) return 0; // 如果进球数为负数，概率为0
	let sum = Math.exp(-lambda);
	for (let i = 0; i <= k; i++) {
		sum *= (lambda ** i) / factorial(i);
	}
	return sum;
}

function calculateGoalProbabilities (homeTeamGoals, homeTeamMatches, awayTeamGoals, awayTeamMatches) {
	// 计算主队和客队的平均进球数
	const avgHomeGoals = homeTeamGoals / homeTeamMatches;
	const avgAwayGoals = awayTeamGoals / awayTeamMatches;

	// 存储进球数的概率
	const homeGoalProbabilities = [];
	const awayGoalProbabilities = [];

	// 假设最大进球数为7，计算泊松分布的概率
	for (let i = 0; i <= 7; i++) {
		homeGoalProbabilities.push(poissonDistribution(avgHomeGoals, i));
		awayGoalProbabilities.push(poissonDistribution(avgAwayGoals, i));
	}

	return {
		home: homeGoalProbabilities,
		away: awayGoalProbabilities
	};
}

function calculateMatchProbabilities (homeProbabilities, awayProbabilities) {
	// 存储所有可能比分及其概率
	const matchProbabilities = {};

	// 遍历所有可能的进球组合
	for (let homeGoals = 0; homeGoals <= 7; homeGoals++) {
		for (let awayGoals = 0; awayGoals <= 7; awayGoals++) {
			const score = `${homeGoals}-${awayGoals}`;
			const probability = homeProbabilities[homeGoals] * awayProbabilities[awayGoals];
			matchProbabilities[score] = (matchProbabilities[score] || 0) + probability;
		}
	}

	// 将比分按概率从大到小排序
	const sortedScores = Object.entries(matchProbabilities)
		.sort((a, b) => b[1] - a[1]);

	// 返回概率最大的三个比分
	return sortedScores.slice(0, 3);
}

/**
 * 
 * @param {主队进球} homeTeamGoals 
 * @param {主队失球} homeTeamMatches 
 * @param {客队进球} awayTeamGoals 
 * @param {客队失球} awayTeamMatches 
 */
export function PoissonCalculation (homeTeamGoals, homeTeamMatches, awayTeamGoals, awayTeamMatches) {
	// 计算进球概率
	const goalProbabilities = calculateGoalProbabilities(homeTeamGoals, homeTeamMatches, awayTeamGoals, awayTeamMatches);

	// 计算比分概率
	const matchProbabilities = calculateMatchProbabilities(goalProbabilities.home, goalProbabilities.away);

	// 输出总进球数的概率
	//console.log("总进球数概率分布:");
	let totalGoals = [];
	for (let i = 0; i <= 7; i++) {
		let totalGoalsProbability = 0;
		for (let j = 0; j <= i; j++) {
			totalGoalsProbability += goalProbabilities.home[j] * goalProbabilities.away[i - j];
		}
		//console.log(`总进球数 ${i}: ${totalGoalsProbability.toFixed(4)}`);
		totalGoals.push({ goal: i, probability: totalGoalsProbability.toFixed(4) });
	}

	// 输出概率最大的三个比分
	//console.log("概率最大的三个比分:");
	let scoreList = [];
	matchProbabilities.forEach(scoreProb => {
		const [score, probability] = scoreProb;
		//console.log(`比分: ${score}, 概率: ${probability.toFixed(4)}`);
		scoreList.push(score);
	});
	return { score: scoreList, totalGoals: totalGoals };
}

