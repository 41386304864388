import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
  class: "content title"
};
import { ref } from 'vue';
import { showNotify } from '@nutui/nutui';
import axios from 'axios';
import HadView from './HadView.vue';
import HHadView from './HHadView.vue';
import HafuView from './HafuView.vue';
import TtgView from './TtgView.vue';
import CrsView from './CrsView.vue';
export default {
  __name: 'AnalyzeView',
  setup(__props, {
    expose: __expose
  }) {
    const timeNotify = msg => {
      showNotify.warn(msg, {
        duration: 2000
      });
    };
    const title = ref('');
    const isVisible = ref(false);
    // 显示弹出框
    const show = matchId => {
      isVisible.value = true;
      getFixedBonusV1(matchId);
    };
    const oddsHistory = ref({});
    const hadRef = ref(null);
    const hhadRef = ref(null);
    const hafuRef = ref(null);
    const ttgRef = ref(null);
    const crsRef = ref(null);
    const getFixedBonusV1 = async matchId => {
      try {
        const response = await axios.get('https://webapi.sporttery.cn/gateway/jc/football/getFixedBonusV1.qry?clientCode=3001&matchId=' + matchId);
        let data = response.data;
        if (data.success) {
          oddsHistory.value = data.value.oddsHistory;
          title.value = oddsHistory.value.homeTeamAbbName + ' VS ' + oddsHistory.value.awayTeamAbbName;
          hadRef.value.pushHadList(oddsHistory.value.hadList);
          hhadRef.value.pushHHadList(oddsHistory.value.hhadList);
          hafuRef.value.pushHafuList(oddsHistory.value.hafuList);
          ttgRef.value.pushTtgList(oddsHistory.value.ttgList);
          crsRef.value.pushCrsList(oddsHistory.value.crsList);
        } else {
          timeNotify(data.errorMessage);
        }
      } catch (error) {
        // 处理错误
        console.error(error);
      }
    };

    // 关闭弹出框
    const close = () => {
      isVisible.value = false;
    };

    // 暴露给外部使用的函数
    __expose({
      show,
      close
    });
    return (_ctx, _cache) => {
      const _component_nut_col = _resolveComponent("nut-col");
      const _component_nut_row = _resolveComponent("nut-row");
      const _component_nut_popup = _resolveComponent("nut-popup");
      return _openBlock(), _createBlock(_component_nut_popup, {
        visible: isVisible.value,
        "onUpdate:visible": _cache[0] || (_cache[0] = $event => isVisible.value = $event),
        closeable: "",
        style: {
          padding: '30px 10px',
          height: '85%',
          width: '90%',
          overflow: 'auto'
        }
      }, {
        default: _withCtx(() => [_createVNode(_component_nut_row, null, {
          default: _withCtx(() => [_createVNode(_component_nut_col, {
            span: 24
          }, {
            default: _withCtx(() => [_createElementVNode("div", _hoisted_1, _toDisplayString(title.value), 1)]),
            _: 1
          })]),
          _: 1
        }), _createVNode(HadView, {
          ref_key: "hadRef",
          ref: hadRef
        }, null, 512), _createVNode(HHadView, {
          ref_key: "hhadRef",
          ref: hhadRef
        }, null, 512), _createVNode(HafuView, {
          ref_key: "hafuRef",
          ref: hafuRef
        }, null, 512), _createVNode(TtgView, {
          ref_key: "ttgRef",
          ref: ttgRef
        }, null, 512), _createVNode(CrsView, {
          ref_key: "crsRef",
          ref: crsRef
        }, null, 512)]),
        _: 1
      }, 8, ["visible"]);
    };
  }
};