import { createRouter, createWebHistory, createWebHashHistory } from 'vue-router';
import LoginView from '../components/LoginView.vue';
import HomeView from '../components/HomeView.vue';
import RegisterView from '../components/RegisterView.vue';
import ExplainView from '../components/ExplainView.vue';
import MatchInfo from '../components/MatchInfo.vue';
import FullTimeView from '../components/FullTimeView.vue';
import store from '../store/index'
import request from '@/utils/request';

const routes = [
	{ path: '/', redirect: '/home' },
	{ path: '/login', component: LoginView, name: "Login" },
	{ path: '/register', component: RegisterView, name: "Register" },
	{ path: '/home', component: HomeView, meta: { requiresAuth: true }, name: "Home" },
	{ path: '/matchInfo/:matchId', component: MatchInfo, meta: { requiresAuth: true }, name: "MatchInfo" },
	{ path: '/explain', component: ExplainView, meta: { requiresAuth: true }, name: "Explain" },
	{ path: '/fullTime', component: FullTimeView, meta: { requiresAuth: true }, name: "FullTime" }
]

const router = createRouter({
	//history: createWebHistory(),
	history: createWebHashHistory(process.env.VUE_APP_BASE_URL),
	routes
})

router.beforeEach((to, from, next) => {
	// 检查是否登录，如果没有登录并且目标路由需要身份验证，则重定向到登录页面
	const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
	if (requiresAuth) {
		request.get('/getInfo').then(res => {
			if (res.code == 200) {
				// 登录成功，更新Vuex状态
				store.dispatch('login', res);
				next();
			} else {
				next({ name: 'Login', query: { redirect: to.fullPath } }) // 将当前路由路径作为参数传递给登录页面
			}
		})
	} else {
		next();
	}
})

export default router