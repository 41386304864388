import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-06e46436"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "content title"
};
import { ref } from 'vue';
import { showNotify } from '@nutui/nutui';
import axios from 'axios';
import * as echarts from 'echarts';
export default {
  __name: 'AnalyzeStepLine',
  setup(__props, {
    expose: __expose
  }) {
    const timeNotify = msg => {
      showNotify.warn(msg, {
        duration: 2000
      });
    };
    const title = ref('');
    const isVisible = ref(false);
    const chartContainer1 = ref(null);
    const chartContainer2 = ref(null);
    const chartContainer3 = ref(null);
    const value = ref(false);
    const match = ref(null);
    const theme = ref('');
    // 显示弹出框
    const show = matchId => {
      isVisible.value = true;
      match.value = matchId;
      getFixedBonusV1(matchId);
    };
    const oddsHistory = ref({});
    const goalLine = ref("");
    const getFixedBonusV1 = async matchId => {
      try {
        const response = await axios.get('https://webapi.sporttery.cn/gateway/jc/football/getFixedBonusV1.qry?clientCode=3001&matchId=' + matchId);
        let data = response.data;
        if (data.success) {
          oddsHistory.value = data.value.oddsHistory;
          goalLine.value = oddsHistory.value.hhadList[0].goalLine;
          title.value = oddsHistory.value.homeTeamAbbName + ' VS ' + oddsHistory.value.awayTeamAbbName;
          if (data.value.sectionsNo999 != '') {
            title.value = oddsHistory.value.homeTeamAbbName + ' ' + data.value.sectionsNo999 + ' ' + oddsHistory.value.awayTeamAbbName;
          }
          const response1 = await axios.get('https://tuhaojie.asia/api-foot/matchGoalWater/getMatchStepLine?matchId=' + matchId);
          //const response1 = await axios.get('http://localhost:8888/matchGoalWater/getMatchStepLine?matchId=' + matchId);
          let data2 = response1.data;
          const chart1 = echarts.init(chartContainer1.value);
          const option1 = {
            title: {
              text: '进球1 2时间概率图'
            },
            tooltip: {
              trigger: 'axis'
            },
            legend: {
              data: ['进球1球', '比分1球', '进球2球', '比分2球'],
              top: 20
            },
            grid: {
              left: '3%',
              right: '4%',
              bottom: '3%',
              containLabel: true
            },
            toolbox: {
              feature: {
                saveAsImage: {}
              }
            },
            xAxis: {
              type: 'category',
              data: data2.time
            },
            yAxis: {
              type: 'value'
            },
            series: [{
              name: '进球1球',
              type: 'line',
              step: 'start',
              data: data2.ttg1
            }, {
              name: '比分1球',
              type: 'line',
              step: 'middle',
              data: data2.crs1
            }, {
              name: '进球2球',
              type: 'line',
              step: 'middle',
              data: data2.ttg2
            }, {
              name: '比分2球',
              type: 'line',
              step: 'middle',
              data: data2.crs2
            }]
          };
          chart1.setOption(option1);
          const chart2 = echarts.init(chartContainer2.value);
          const option2 = {
            title: {
              text: '进球3 4时间概率图'
            },
            tooltip: {
              trigger: 'axis'
            },
            legend: {
              data: ['进球3球', '比分3球', '进球4球', '比分4球'],
              top: 20
            },
            grid: {
              left: '3%',
              right: '4%',
              bottom: '3%',
              containLabel: true
            },
            toolbox: {
              feature: {
                saveAsImage: {}
              }
            },
            xAxis: {
              type: 'category',
              data: data2.time
            },
            yAxis: {
              type: 'value'
            },
            series: [{
              name: '进球3球',
              type: 'line',
              step: 'start',
              data: data2.ttg3
            }, {
              name: '比分3球',
              type: 'line',
              step: 'middle',
              data: data2.crs3
            }, {
              name: '进球4球',
              type: 'line',
              step: 'middle',
              data: data2.ttg4
            }, {
              name: '比分4球',
              type: 'line',
              step: 'middle',
              data: data2.crs4
            }]
          };
          chart2.setOption(option2);
          const chart3 = echarts.init(chartContainer3.value);
          const option3 = {
            title: {
              text: '进球5 6时间概率图'
            },
            tooltip: {
              trigger: 'axis'
            },
            legend: {
              data: ['进球5球', '比分5球', '进球6球', '比分6球'],
              top: 20
            },
            grid: {
              left: '3%',
              right: '4%',
              bottom: '3%',
              containLabel: true
            },
            toolbox: {
              feature: {
                saveAsImage: {}
              }
            },
            xAxis: {
              type: 'category',
              data: data2.time
            },
            yAxis: {
              type: 'value'
            },
            series: [{
              name: '进球5球',
              type: 'line',
              step: 'start',
              data: data2.ttg5
            }, {
              name: '比分5球',
              type: 'line',
              step: 'middle',
              data: data2.crs5
            }, {
              name: '进球6球',
              type: 'line',
              step: 'middle',
              data: data2.ttg6
            }, {
              name: '比分6球',
              type: 'line',
              step: 'middle',
              data: data2.crs6
            }]
          };
          chart3.setOption(option3);
        } else {
          timeNotify(data.errorMessage);
        }
      } catch (error) {
        // 处理错误
        console.error(error);
      }
    };

    // 关闭弹出框
    const close = () => {
      isVisible.value = false;
    };

    // 暴露给外部使用的函数
    __expose({
      show,
      close
    });
    return (_ctx, _cache) => {
      const _component_nut_col = _resolveComponent("nut-col");
      const _component_nut_row = _resolveComponent("nut-row");
      const _component_nut_divider = _resolveComponent("nut-divider");
      const _component_nut_popup = _resolveComponent("nut-popup");
      return _openBlock(), _createBlock(_component_nut_popup, {
        visible: isVisible.value,
        "onUpdate:visible": _cache[0] || (_cache[0] = $event => isVisible.value = $event),
        closeable: "",
        style: {
          padding: '30px 10px',
          height: '85%',
          width: '90%',
          overflow: 'auto'
        }
      }, {
        default: _withCtx(() => [_createVNode(_component_nut_row, null, {
          default: _withCtx(() => [_createVNode(_component_nut_col, {
            span: 24
          }, {
            default: _withCtx(() => [_createElementVNode("div", _hoisted_1, "(" + _toDisplayString(goalLine.value) + ")" + _toDisplayString(title.value), 1)]),
            _: 1
          })]),
          _: 1
        }), _createVNode(_component_nut_divider, {
          "content-position": "left"
        }, {
          default: _withCtx(() => [_createTextVNode("概率上升，赔率下降。寻找异常")]),
          _: 1
        }), _createElementVNode("div", {
          ref_key: "chartContainer1",
          ref: chartContainer1,
          style: {
            "width": "90vw",
            "height": "500px"
          }
        }, null, 512), _createElementVNode("div", {
          ref_key: "chartContainer2",
          ref: chartContainer2,
          style: {
            "width": "90vw",
            "height": "500px"
          }
        }, null, 512), _createElementVNode("div", {
          ref_key: "chartContainer3",
          ref: chartContainer3,
          style: {
            "width": "90vw",
            "height": "500px"
          }
        }, null, 512)]),
        _: 1
      }, 8, ["visible"]);
    };
  }
};