import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
  class: "content title"
};
const _hoisted_2 = {
  style: {
    "font-weight": "800",
    "font-size": "20px"
  }
};
const _hoisted_3 = {
  style: {
    "font-weight": "800",
    "font-size": "20px"
  }
};
import { ref } from 'vue';
import { showNotify } from '@nutui/nutui';
import axios from 'axios';
import HHadList from './HHadList.vue';
import HadList from './HadList.vue';
import TtgConvert from './TtgConvert.vue';
import CrsConvert from './CrsConvert.vue';
import { getCrsRecommend, getHafuRecommend, decryptData } from './calculation.js';
export default {
  __name: 'AnalyzeList',
  setup(__props, {
    expose: __expose
  }) {
    const timeNotify = msg => {
      showNotify.warn(msg, {
        duration: 2000
      });
    };
    const title = ref('');
    const isListVisible = ref(false);
    // 显示弹出框
    const show = matchId => {
      isListVisible.value = true;
      getFixedBonusV1(matchId);
      // getRecommended(matchId);
    };
    const recommended = ref({});
    const oddsHistory = ref({});
    const hadRef = ref(null);
    const hhadRef = ref(null);
    const crsRecommend = ref({});
    const hafuRecommend = ref({});
    const bosong = ref({});
    const getFixedBonusV1 = async matchId => {
      try {
        const response = await axios.get('https://webapi.sporttery.cn/gateway/jc/football/getFixedBonusV1.qry?clientCode=3001&matchId=' + matchId);
        let data = response.data;
        if (data.success) {
          oddsHistory.value = data.value.oddsHistory;
          title.value = oddsHistory.value.homeTeamAbbName + ' VS ' + oddsHistory.value.awayTeamAbbName;
          hadRef.value.pushHadList(oddsHistory.value.hadList);
          hhadRef.value.pushHHadList(oddsHistory.value.hhadList);
          crsRecommend.value = getCrsRecommend(oddsHistory.value.crsList);
          hafuRecommend.value = getHafuRecommend(oddsHistory.value.hafuList);
        } else {
          timeNotify(data.errorMessage);
        }
      } catch (error) {
        // 处理错误
        console.error(error);
      }
    };
    const getRecommended = async matchId => {
      try {
        const response = await axios.get('http://localhost:8888/match/getRecommended?matchId=' + matchId);
        let data = decryptData(response.data);
        recommended.value = data;
      } catch (error) {
        // 处理错误
        console.error(error);
      }
    };

    // 关闭弹出框
    const close = () => {
      isListVisible.value = false;
    };

    // 暴露给外部使用的函数
    __expose({
      show,
      close
    });
    return (_ctx, _cache) => {
      const _component_nut_col = _resolveComponent("nut-col");
      const _component_nut_row = _resolveComponent("nut-row");
      const _component_nut_popup = _resolveComponent("nut-popup");
      return _openBlock(), _createBlock(_component_nut_popup, {
        visible: isListVisible.value,
        "onUpdate:visible": _cache[0] || (_cache[0] = $event => isListVisible.value = $event),
        closeable: "",
        style: {
          padding: '30px 10px',
          height: '85%',
          width: '90%',
          overflow: 'auto'
        }
      }, {
        default: _withCtx(() => [_createVNode(_component_nut_row, null, {
          default: _withCtx(() => [_createVNode(_component_nut_col, {
            span: 24
          }, {
            default: _withCtx(() => [_createElementVNode("div", _hoisted_1, _toDisplayString(title.value), 1)]),
            _: 1
          })]),
          _: 1
        }), _createVNode(HadList, {
          ref_key: "hadRef",
          ref: hadRef
        }, null, 512), _createVNode(HHadList, {
          ref_key: "hhadRef",
          ref: hhadRef
        }, null, 512), _createElementVNode("div", _hoisted_2, "比分胜平负推荐 首选：" + _toDisplayString(crsRecommend.value.firstOption) + " 次选：" + _toDisplayString(crsRecommend.value.secondOption), 1), _createElementVNode("div", _hoisted_3, "半全胜平负推荐 首选：" + _toDisplayString(hafuRecommend.value.firstOption) + " 次选：" + _toDisplayString(hafuRecommend.value.secondOption), 1)]),
        _: 1
      }, 8, ["visible"]);
    };
  }
};