import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx } from "vue";
import { h, ref } from 'vue';
import { Home, Find, My, Category, Cart } from '@nutui/icons-vue';
// import NewList from './NewList.vue'
// import HistoryList from './HistoryList.vue'
import MyViewVue from './MyView.vue';
// import FutureList from './FutureList.vue'
import MacthList from './MacthList.vue';
import CollectList from './CollectList.vue';
import SendView from './SendView.vue';
import Spdex from './Spdex.vue';
// import OldView from './OldView.vue'

export default {
  __name: 'HomeView',
  setup(__props) {
    const List = [{
      title: '主页',
      icon: h(Home),
      to: MacthList
    }, {
      title: '收藏',
      icon: h(Cart),
      to: CollectList
    },
    // {
    //   title: '历史',
    //   icon: h(Find),
    //   to: HistoryList
    // },
    {
      title: '计算',
      icon: h(Category),
      to: Spdex
    },
    // {
    //   title: '聊天',
    //   icon: h(Find),
    //   to: SendView
    // },
    // {
    //   title: '老的',
    //   icon: h(Category),
    //   to: NewList
    // },
    {
      title: '我的',
      icon: h(My),
      to: MyViewVue
    }];
    const tabSwitch = (item, index) => {
      localStorage.setItem('tabSwitch', index);
      //console.log(item, index);
    };
    const active = ref(localStorage.getItem("tabSwitch") != undefined ? localStorage.getItem("tabSwitch") : 0);
    return (_ctx, _cache) => {
      const _component_nut_tabbar_item = _resolveComponent("nut-tabbar-item");
      const _component_nut_tabbar = _resolveComponent("nut-tabbar");
      return _openBlock(), _createElementBlock(_Fragment, null, [(_openBlock(), _createBlock(_resolveDynamicComponent(List[active.value].to), {
        style: {
          height: '94vh',
          overflow: 'auto'
        }
      })), _createVNode(_component_nut_tabbar, {
        modelValue: active.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => active.value = $event),
        bottom: "",
        "safe-area-inset-bottom": "",
        placeholder: "",
        onTabSwitch: tabSwitch
      }, {
        default: _withCtx(() => [(_openBlock(), _createElementBlock(_Fragment, null, _renderList(List, (item, index) => {
          return _createVNode(_component_nut_tabbar_item, {
            key: index,
            "tab-title": item.title,
            icon: item.icon
          }, null, 8, ["tab-title", "icon"]);
        }), 64))]),
        _: 1
      }, 8, ["modelValue"])], 64);
    };
  }
};